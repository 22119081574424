import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Empty, message, Table, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTransferRequests } from '../api/clubApi';
import { UserContext } from '../App';
import { NewTransferModal } from './newTransferModal';
import { StyledDiv } from './styled/styledDiv';

const LIMIT = 10;

export const TransferWindow = () => {
	const [showTransferModal, setShowTransferModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const navigate = useNavigate();

	const [data, setData] = useState(null);
	const [transfersCount, setTransfersCount] = useState();
	const [currentPage, setCurrentPage] = useState(1);

	const { club } = useContext(UserContext);

	async function loadTransfers() {
		if (!club) return;

		const offset = (currentPage - 1) * LIMIT;

		setLoading(true);
		getTransferRequests(LIMIT, offset, searchText)
			.then((res) => {
				setTransfersCount(res.data.transfers.count);
				const transfers = res.data.transfers.rows.map((item) => ({
					registrationNumber: item.playerRegistrationNumber,
					key: item.playerId,
					transferId: item.transferId,
					playerName: item.playerName,
					fromClubId: `${item.fromClubName} | ${item.fromClubId}`,
					createdAt: new Date(item.createdAt).toLocaleDateString(),
					status: getTransferStatus(item.status),
				}));

				setData(transfers);
			})
			.catch((err) => {
				message.error('Could not connect with the server. Please try again.');
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const getTransferStatus = (status) => {
		const STATUSES = {
			0: 'Pending',
			1: 'On Hold',
			2: 'Verified',
			3: 'Completed',
		};

		const statusColors = {
			Completed: 'green',
			Pending: 'gray',
			Verified: 'blue',
			'On Hold': 'red',
		};

		return <Tag color={statusColors[STATUSES[status]]}>{STATUSES[status]}</Tag>;
	};

	const emptyComponent = {
		emptyText: (
			<Empty
				description={
					<span>
						{searchText === ''
							? 'No pending transfer requests.'
							: 'No results matching your search input.'}
					</span>
				}
			/>
		),
	};

	const getIndividualTransferDetails = (id) => {
		navigate(`/transfer/${id}`, { state: id });
	};

	const columns = [
		{
			title: 'Transfer ID',
			dataIndex: 'transferId',
			width: '10%',
		},
		{
			title: 'Player Name',
			dataIndex: 'playerName',
			width: '25%',
		},
		{
			title: 'From',
			dataIndex: 'fromClubId',
			width: '25%',
		},
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			width: '10%',
		},
		{
			title: 'Transfer Status',
			dataIndex: 'status',
			width: '10%',
		},
	];

	useEffect(() => {
		loadTransfers();
	}, [club, currentPage]);

	return (
		<div>
			<NewTransferModal
				isModalVisible={showTransferModal}
				setModalVisible={setShowTransferModal}
				loadTransfers={loadTransfers}
			/>
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<StyledDiv>
					<Search
						placeholder="Search using Register Number, Name or Email"
						value={searchText}
						onSearch={loadTransfers}
						onChange={(e) => setSearchText(e.target.value)}
						disabled={loading}
						enterButton
					/>
				</StyledDiv>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div
					style={{
						width: '90%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row ',
							paddingBottom: '10px',
						}}
					>
						<h1
							style={{
								fontSize: 'large',
								fontWeight: 'bolder',
								display: 'flex',
								justifyContent: 'flex-start',
							}}
						>
							PENDING TRANSFER REQUESTS
						</h1>
						<Button
							icon={<PlusCircleOutlined />}
							style={{ marginLeft: 'auto' }}
							type="primary"
							onClick={() => setShowTransferModal(true)}
							disabled={loading}
						>
							New Transfer
						</Button>
					</div>
					<Table
						columns={columns}
						locale={emptyComponent}
						loading={loading}
						dataSource={data}
						rowKey="transferId"
						pagination={{
							current: currentPage,
							pageSize: LIMIT,
							total: transfersCount,
							onChange: (value) => setCurrentPage(value),
						}}
						onRow={(record) => {
							return {
								onClick: () => {
									getIndividualTransferDetails(record.transferId);
								},
							};
						}}
					/>
				</div>
			</div>
		</div>
	);
};
