import { Table, message, Input, Tag, Avatar, Empty, Radio } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, getPlayers } from '../api/clubApi';
import { UserContext } from '../App';
import { PlayerModal } from './playerAddModal';
import { StyledDiv } from './styled/styledDiv';
import avatar from '../assets/avatar.png';

export const Players = () => {
	let navigate = useNavigate();

	const [data, setData] = useState(null);
	const [mainData, setMainData] = useState([]);
	const [playerCount, setPlayerCount] = useState();
	const [active, setActive] = useState('active');

	const [isModalVisible, setModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');

	const { club } = useContext(UserContext);

	const getPlayerStatus = (status) => {
		const STATUSES = {
			0: 'Pending',
			1: 'On Hold',
			2: 'Verified',
			3: 'Approved',
		};

		const statusColors = {
			Verified: 'blue',
			Pending: 'gray',
			Approved: 'green',
			'On Hold': 'red',
		};

		return <Tag color={statusColors[STATUSES[status]]}>{STATUSES[status]}</Tag>;
	};

	async function loadPlayers() {
		if (!club) return;

		setLoading(true);
		getPlayers(club.clubId, active)
			.then((res) => {
				setPlayerCount(res.data.players.count);
				let rows = [];
				res.data.players.rows.map((item) => {
					const player = {
						key: item.playerId,
						regno: item.registrationNumber,
						fullName: item.fullName,
						name: (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Avatar
									src={
										item.photo
											? BASE_URL + `get-player-image?key=${item.photo}`
											: avatar
									}
								/>
								<label style={{ paddingLeft: '15px' }}>{item.fullName}</label>
								<Tag
									style={{ marginLeft: 'auto' }}
									color={item.isActive ? 'green' : 'default'}
								>
									{item.isActive ? 'Active' : 'Inactive'}
								</Tag>
							</div>
						),
						email: item.email || '-',
						status: getPlayerStatus(item.status),
						createdAt: new Date(item.createdAt).toLocaleDateString(),
					};
					return rows.push(player);
				});
				setData(rows);
				setMainData(rows);
			})
			.catch((err) => {
				message.error('Could not connect with the server. Please try again.');
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		loadPlayers();
	}, [club, active]);

	const showModal = () => {
		setModalVisible(true);
	};

	const getIndividualPlayerDetails = (id) => {
		navigate(`/players/${id}`, { state: id });
	};

	const columns = [
		{
			title: 'Reg. Number',
			dataIndex: 'regno',
			width: '10%',
		},
		{
			title: 'Full Name',
			dataIndex: 'name',
			width: '30%',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			width: '30%',
		},

		{
			title: 'Created At',
			dataIndex: 'createdAt',
			width: '10%',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			width: '10%',
		},
	];

	const handleSearch = () => {
		if (searchText !== '') {
			let filterArray = [];
			mainData.forEach((item) => {
				const nameRecord = item.fullName.toLowerCase();
				if (nameRecord.includes(searchText.toLowerCase())) {
					const exists = filterArray.filter((keys) => keys.key === item.key);
					if (exists.length === 0) {
						filterArray.push(item);
					}
				}
				if (item.email?.includes(searchText)) {
					const exists = filterArray.filter((keys) => keys.key === item.key);
					if (exists.length === 0) {
						filterArray.push(item);
					}
				}
				if (item.regno.includes(searchText)) {
					const exists = filterArray.filter((keys) => keys.key === item.key);
					if (exists.length === 0) {
						filterArray.push(item);
					}
				}
			});
			setData(filterArray);
		} else {
			setData([...mainData]);
		}
	};

	useEffect(() => {
		handleSearch();
	}, [searchText]);

	const emptyComponent = {
		emptyText: (
			<Empty
				description={
					<span>
						{searchText === ''
							? 'Players in this clubs will be listed here.'
							: 'No results matching your search input.'}
					</span>
				}
			/>
		),
	};

	return (
		<div>
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<StyledDiv>
					<Input.Group compact>
						<Input
							style={{ width: '100%' }}
							placeholder="Search using Register Number, Name or Email"
							onChange={(e) => {
								setSearchText(e.target.value);
							}}
							disabled={loading}
						/>
					</Input.Group>
				</StyledDiv>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div
					style={{
						width: '90%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							paddingBottom: '8px',
						}}
					>
						<h1
							style={{
								fontSize: 'large',
								fontWeight: 'bolder',
								display: 'flex',
								justifyContent: 'flex-start',
							}}
						>
							{active.toUpperCase() + ' '}PLAYERS
							{!loading && ' (' + playerCount + ')'}
						</h1>
						<div style={{ marginLeft: 'auto' }}>
							<Radio.Group
								onChange={(e) => setActive(e.target.value)}
								value={active}
								disabled={loading}
								buttonStyle="solid"
							>
								<Radio.Button value="active">Active</Radio.Button>
								<Radio.Button value="inactive">In-Active</Radio.Button>
								<Radio.Button value="all">All</Radio.Button>
							</Radio.Group>
						</div>
					</div>
					<Table
						columns={columns}
						locale={emptyComponent}
						loading={loading}
						dataSource={data}
						pagination={{ pageSize: 10 }}
						onRow={(record, index) => {
							return {
								onClick: (event) => {
									getIndividualPlayerDetails(record.regno);
								},
							};
						}}
					/>
				</div>
			</div>
			<PlayerModal
				isModalVisible={isModalVisible}
				setModalVisible={setModalVisible}
			/>
		</div>
	);
};
