import {
	FullscreenOutlined,
	LeftCircleOutlined,
	SaveFilled,
} from '@ant-design/icons';
import {
	Alert,
	Button,
	Card,
	Image,
	message,
	Popconfirm,
	Spin,
	Tag,
	Tooltip,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	BASE_URL,
	checkPlayerByRegisterNumber,
	getTransferById,
	updateTransfer,
	uploadPlayerTransferForm,
} from '../api/clubApi';
import { UserContext } from '../App';
import avatar from '../assets/avatar.png';
import { useRef } from 'react';
import { MAX_DOCUMENT_SIZE, SUPPORTED_DOCUMENT_MIMETYPES } from '../constants';

const TRANSFER_STATUSES = {
	pending: 0,
	onHold: 1,
	verified: 2,
	verified: 3,
};

export const IndividualTransfer = () => {
	const navigate = useNavigate();
	const { transferId } = useParams();

	const [player, setPlayer] = useState(null);
	const [transfer, setTransfer] = useState(null);
	const [loading, setLoading] = useState(false);

	const [isEdited, setIsEdited] = useState(false);

	const [transferFormKey, setTransferFormKey] = useState(null); // Storage key
	const transferFormRef = useRef(null);

	const { club, documentsConfig } = useContext(UserContext);

	function getSignedDocumentLink(link) {
		const token = localStorage.getItem('token');
		return `${BASE_URL}club/get-player-document?token=${token}&key=${link}`;
	}

	async function getPlayerByRegisterNumber() {
		if (!club) return;

		setLoading(true);
		getTransferById(club.clubId, transferId)
			.then((res) => {
				const transferData = res.data.transfer;
				setTransfer(transferData);

				setTransferFormKey(transferData.transferDocument);

				checkPlayerByRegisterNumber(transferData.playerRegistrationNumber)
					.then((res) => {
						setPlayer(res.data.player);
					})
					.catch((err) => {
						message.error('Something went wrong! Please try again.');
					});
			})
			.catch((err) => {
				message.error('Something went wrong! Please try again.');
				console.error(err);
			})
			.finally(() => setLoading(false));
	}

	function onSelectDocument(e) {
		const file = e.target.files[0];

		if (!file) {
			message.error('Please select a file to continue');
			return;
		}

		if (!SUPPORTED_DOCUMENT_MIMETYPES.includes(file.type)) {
			message.error('Please select an image or PDF file');
			return;
		}

		if (file.size > MAX_DOCUMENT_SIZE) {
			message.error('Only files with less than 5MB are allowed!');
			return;
		}

		setLoading(true);

		uploadPlayerTransferForm(file, player.s3Id)
			.then((res) => {
				setTransferFormKey(res.data.key);
				setIsEdited(true);
			})
			.catch(() => {
				message.error(
					'Something went wrong! Failed to upload transfer document.',
				);
			})
			.finally(() => setLoading(false));
	}

	async function handleSaveTransfer() {
		setLoading(true);

		try {
			const res = await updateTransfer(transferFormKey, transferId);

			setTransfer(res.data.transfer);
			setIsEdited(false);

			message.success('Transfer request saved and re-submitted!');
		} catch {
		} finally {
			setLoading(false);
		}
	}

	const getTransferStatus = (status) => {
		const STATUSES = {
			0: 'Pending',
			1: 'On Hold',
			2: 'Verified',
			3: 'Completed',
		};

		const statusColors = {
			Completed: 'green',
			Pending: 'gray',
			Verified: 'blue',
			'On Hold': 'red',
		};

		return <Tag color={statusColors[STATUSES[status]]}>{STATUSES[status]}</Tag>;
	};

	useEffect(() => {
		getPlayerByRegisterNumber();
	}, [club]);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				marginBottom: '20px',
			}}
		>
			<div style={{ width: '70%' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'left',
						gap: '1em',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'left',
							paddingBottom: '18px',
							flex: 0.01,
						}}
					>
						<Button
							icon={<LeftCircleOutlined />}
							style={{
								marginTop: '20px',
								border: '2px solid black',
								backgroundColor: 'transparent',
								color: 'black',
							}}
							onClick={() => {
								navigate('/transfer', { replace: true });
							}}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'left',
							flex: 0.99,
						}}
					>
						<h1
							style={{
								fontSize: 'large',
								fontWeight: 'bolder',
								marginTop: '2%',
								display: 'flex',
								justifyContent: 'flex-start',
								paddingTop: '6px',
							}}
						>
							TRANSFER DETAILS
						</h1>
					</div>
				</div>
				{player && transfer ? (
					<Card
						title={
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Tooltip title="Player will be transferred to your club after approved by DIFA Registration Desk and DIFA Official">
									{getTransferStatus(transfer.status)}
								</Tooltip>

								{isEdited && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											gap: '5px',
											alignItems: 'center',
											marginLeft: 'auto',
										}}
									>
										<Popconfirm
											title="Saving this transfer will re-submit the tranfer. Continue?"
											onConfirm={handleSaveTransfer}
										>
											<Button
												type="primary"
												icon={<SaveFilled />}
												loading={loading}
											>
												Save
											</Button>
										</Popconfirm>
									</div>
								)}
							</div>
						}
					>
						{transfer.status === TRANSFER_STATUSES.onHold && (
							<Alert
								style={{ marginBottom: '20px' }}
								message="This transfer has been with held! Please re-upload your transfer documents!"
								type="error"
								showIcon
							/>
						)}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								gap: '1em',
							}}
						>
							<Image
								src={
									player.photo
										? BASE_URL + `get-player-image?key=${player.photo}`
										: avatar
								}
								width="20%"
								style={{ borderRadius: '50%' }}
							/>
							<h2>{player.fullName}</h2>
						</div>

						<Card style={{ marginTop: '1em', marginBottom: '1em' }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									gap: 'auto',
									alignItems: 'center',
								}}
							>
								{transfer.status === TRANSFER_STATUSES.onHold ? (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											width: '100%',
										}}
									>
										<div>
											{documentsConfig && documentsConfig.transferDocument && (
												<label style={{ color: 'red' }}>{'* '}</label>
											)}
											Transfer Form
										</div>
										<div style={{ marginLeft: 'auto' }}>
											{transferFormKey && (
												<a
													className="link"
													href={
														transferFormKey
															? getSignedDocumentLink(transferFormKey)
															: null
													}
													target="_blank"
													rel="noopener noreferrer"
												>
													<Button
														type="primary"
														icon={<FullscreenOutlined />}
														disabled={loading}
													>
														View
													</Button>
												</a>
											)}

											<Button
												style={{ marginLeft: '10px' }}
												type="dashed"
												onClick={() => {
													transferFormRef.current.click();
												}}
												disabled={loading}
											>
												<input
													type="file"
													id="transferForm"
													accept="image/*,application/pdf"
													ref={transferFormRef}
													onChange={onSelectDocument}
													style={{ display: 'none' }}
												/>

												{transferFormKey ? 'Change' : 'Upload'}
											</Button>
										</div>
									</div>
								) : (
									<>
										<div>Transfer Form</div>
										<div style={{ marginLeft: 'auto' }}>
											<a
												className="link"
												href={
													transfer.transferDocument
														? getSignedDocumentLink(transfer.transferDocument)
														: null
												}
												target="_blank"
												rel="noopener noreferrer"
											>
												<Button
													type="primary"
													icon={<FullscreenOutlined />}
													disabled={loading || !transfer.transferDocument}
												>
													{transfer.transferDocument
														? 'View'
														: 'No Transfer Document!'}
												</Button>
											</a>
										</div>
									</>
								)}
							</div>
						</Card>

						<div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
							<Card style={{ width: '50%' }}>
								<h4>Register Number</h4>
								<div>
									<p>{player.registrationNumber}</p>
								</div>
							</Card>
							<Card style={{ width: '50%' }}>
								<h4>Current Club</h4>
								<div>
									<p>{player.club ? player.club.name : '-'}</p>
								</div>
							</Card>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '1em',
								paddingTop: '1em',
							}}
						>
							<Card style={{ width: '50%' }}>
								<h4>Email</h4>
								<div>
									<p>{player.email || '-'}</p>
								</div>
							</Card>
							<Card style={{ width: '50%' }}>
								<h4>Phone</h4>
								<div>
									<p>{player.phone || '-'}</p>
								</div>
							</Card>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '1em',
								paddingTop: '1em',
							}}
						>
							<Card style={{ width: '50%' }}>
								<h4>Date Of Birth</h4>
								<div>
									<p>{player.dateOfBirth || '-'}</p>
								</div>
							</Card>

							<Card style={{ width: '50%' }}>
								<h4>Blood Group</h4>
								<div>
									<p>{player.bloodGroup || '-'}</p>
								</div>
							</Card>
						</div>
					</Card>
				) : (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
							height: '80vh',
						}}
					>
						<Spin style={{ fontSize: 'xx-large', color: 'blue' }} />
					</div>
				)}
			</div>
		</div>
	);
};
